import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router";
import axios from '../../axiosConfig';
import styled from 'styled-components';

// Стили для компонента
const Container = styled.div`
    display: flex;
    height: 100vh;
    padding: 20px;
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const FormContainer = styled.div`
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
`;

const FormTitle = styled.h2`
    text-align: center;
    margin-bottom: 20px;
`;

const StyledButton = styled.button`
    width: 100%;
    background-color: #1abc9c;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;

    &:hover {
        background-color: #16a085;
    }
`;

const CatalogButton = styled.button`
    width: 100%;
    background-color: #3498db;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;

    &:hover {
        background-color: #2980b9;
    }
`;

const FileInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;

const FileInputLabel = styled.label`
    background-color: #1abc9c;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #16a085;
    }
`;

const HiddenFileInput = styled.input`
    display: none;
`;

const UpdateCeiling = () => {
    const navigate = useNavigate();
    const { state } = useLocation(); // Получаем переданный объект item через state
    const { item } = state || {}; // Если state есть, то достаем item

    const [title, setTitle] = useState(item?.title || '');
    const [code, setCode] = useState(item?.code || '');
    const [imageUrl, setImageUrl] = useState(item?.image || '');
    const [image, setImage] = useState(null);
    const [pricePerSquare, setPricePerSquare] = useState(item?.price_per_square || '');
    const [error, setError] = useState('');
    const [maxWidth] = useState(600);
    const [maxHeight] = useState(600);

    // Загрузка изображения и изменение state
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = function (event) {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const scale = Math.min(maxWidth / img.width, maxHeight / img.height);
                canvas.width = img.width * scale;
                canvas.height = img.height * scale;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                    const resizedFile = new File([blob], file.name, { type: file.type });
                    setImage(resizedFile);
                }, file.type);
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
        setImageUrl(URL.createObjectURL(file));
    };

    // Загрузка изображения на сервер
    const handleImageUpload = async () => {
        if (image) {
            const formData = new FormData();
            formData.append('image', image);

            try {
                const response = await axios.post('/file/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                return response.data.image_url;
            } catch (error) {
                setError('Ошибка загрузки изображения');
                return null;
            }
        }
        return imageUrl; // Если новое изображение не загружено, используем старое
    };

    // Обновление товара
    const handleSubmit = async (event) => {
        event.preventDefault();
        const uploadedImageUrl = await handleImageUpload();

        if (uploadedImageUrl) {
            try {
                const token = localStorage.getItem('authToken');
                await axios.post('/ceiling/update', {
                    sk: item.sk, // Используем идентификатор из переданного объекта item
                    title,
                    code,
                    image: uploadedImageUrl,
                    price_per_square: pricePerSquare,
                }, {
                    headers: {
                        'X-Token': token,
                    },
                });
                navigate('/catalog');
            } catch (error) {
                setError('Ошибка обновления данных');
            }
        }
    };

    // Возврат в каталог
    const handleCatalogRedirect = () => {
        navigate('/catalog');
    };

    return (
        <Container>
            <Content>
                <FormContainer>
                    <FormTitle>Обновить товар</FormTitle>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="Название"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                            style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                        />
                        <input
                            type="text"
                            placeholder="Код"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            required
                            style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                        />
                        <div>
                            <FileInputWrapper>
                                <FileInputLabel htmlFor="file-upload">
                                    Выбрать изображение
                                </FileInputLabel>
                                <HiddenFileInput
                                    id="file-upload"
                                    type="file"
                                    onChange={handleImageChange}
                                    accept="image/*"
                                />
                                {imageUrl && (
                                    <img
                                        src={imageUrl}
                                        alt="Выбранное изображение"
                                        style={{ maxWidth: '150px', maxHeight: '150px', marginTop: '10px' }}
                                    />
                                )}
                            </FileInputWrapper>
                        </div>
                        <input
                            type="text"
                            placeholder="Цена за м²"
                            value={pricePerSquare}
                            onChange={(e) => setPricePerSquare(e.target.value)}
                            required
                            style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <StyledButton type="submit">Обновить</StyledButton>
                    </form>
                    <CatalogButton onClick={handleCatalogRedirect}>В каталог</CatalogButton>
                </FormContainer>
            </Content>
        </Container>
    );
};

export default UpdateCeiling;
