import React, {useRef} from 'react';
import {useLoader} from "@react-three/fiber";
import {TextureLoader} from "three";
import Lamp from "./Lamp";
import Table from "./Table";

function Room({width, depth, height, floor, ...props}) {
    const floorTexture = useLoader(TextureLoader, '/textures/floor.jpg');
    const wallTexture = useLoader(TextureLoader, '/textures/wall.jpg');
    const material = useRef();

    const floorMeshes = [];

    if (floor) {
        for (let x = width - 1; x >= 0; x--) {
            for (let z = depth - 1; z >= 0; z--) {
                const isEdgeX = x < 1;
                const isEdgeZ = z < 1;

                const sizeX = isEdgeX && width % 1 !== 0 ? width % 1 + 1 : 1;
                const sizeZ = isEdgeZ && depth % 1 !== 0 ? depth % 1 + 1 : 1;
                const posX = x - width / 2 + 0.5 - (isEdgeX && width % 1 !== 0 ? (width % 1) / 2  : 0);
                const posZ = z - depth / 2 + 0.5 - (isEdgeZ && depth % 1 !== 0 ? (depth % 1) / 2 : 0);

                floorMeshes.push(
                    <mesh key={`floor-${x}-${z}`} position={[posX, 0, posZ]}>
                        <boxGeometry args={[sizeX, 0.05, sizeZ]} />
                        <meshStandardMaterial ref={material} map={floorTexture} />
                    </mesh>
                );
            }
        }
        floorMeshes.push(
            <>
                <Lamp position={[0, 0.56, 0]}/>
                <Table position={[0, 0, 0]}/>
            </>
        )
    }

    return (
        <group {...props}>
            {floorMeshes}
            {/* Стены */}
            <mesh position={[0, height / 2, -depth / 2]}>
                <boxGeometry args={[width, height + 0.04, 0.05]}/>
                <meshStandardMaterial ref={material} map={wallTexture}/>
            </mesh>
            <mesh position={[width / 2, height / 2, 0]}>
                <boxGeometry args={[0.05, height + 0.04, depth + 0.01]}/>
                <meshStandardMaterial ref={material} map={wallTexture}/>
            </mesh>
            <mesh position={[-width / 2, height / 2, 0]}>
                <boxGeometry args={[0.05, height + 0.04, depth + 0.01]}/>
                <meshStandardMaterial ref={material} map={wallTexture}/>
            </mesh>

            {/* Light Source */}
            <pointLight intensity={1} position={[0, 0.75, 0]} />
        </group>
    );
}

export default Room;
