import React, { useState } from 'react';
import axios from '../../axiosConfig';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    padding: 20px;
`;

const FormContainer = styled.div`
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;

const StyledButton = styled.button`
    width: 100%;
    background-color: #1abc9c;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;

    &:hover {
        background-color: #16a085;
    }
`;

const ErrorText = styled.p`
    color: red;
    text-align: center;
    margin-top: 10px;
`;

const LoginLink = styled.a`
    display: block;
    text-align: center;
    margin-top: 15px;
    color: #3498db;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');
        try {
            const response = await axios.post('/user/login', { username, password });
            localStorage.setItem('authToken', response.data.access_token);
            navigate('/admin');
        } catch (error) {
            setError(error.response?.data?.message || 'Произошла ошибка при входе');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <FormContainer>
                <h2 style={{ textAlign: 'center' }}>Вход</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Пользователь"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Пароль"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                        required
                    />
                    {error && <ErrorText>{error}</ErrorText>}
                    <StyledButton type="submit">
                        {loading ? 'Загрузка...' : 'Вход'}
                    </StyledButton>
                </form>
                <LoginLink href="/register">Регистрация</LoginLink>
            </FormContainer>
        </Container>
    );
};

export default Login;
