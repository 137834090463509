import React, { useEffect, useState } from 'react';
import axios from '../../axiosConfig';
import styled from 'styled-components';
import { useNavigate } from "react-router";
import Sidebar from "./Sidebar";
import Modal from '../utils/Modal';  // Импортируем модальное окно

// Стили для контейнера и карточек
const Container = styled.div`
    display: flex;
`;

const Content = styled.div`
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

const CatalogTitle = styled.h2`
    text-align: center;
    margin-bottom: 20px;
`;

const CatalogGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
`;

const Card = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
`;

const CardImage = styled.img`
    width: 100%;
    border-radius: 5px;
    height: 200px;
    object-fit: cover;
`;

const CardTitle = styled.h3`
    margin: 10px 0;
`;

const CardText = styled.p`
    color: gray;
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

const DeleteButton = styled.button`
    background-color: #e74c3c;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #c0392b;
    }
`;

const UpdateButton = styled.button`
    background-color: #1abb9b;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #117864;
    }
`;

const CreateButton = styled.button`
    background-color: #1abb9b;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;

    &:hover {
        background-color: #117864;
    }
`;

// Стили для контейнера поиска и кнопок
const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`;

const SearchInput = styled.input`
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px 0 0 5px;
    width: 100%;
    max-width: 400px;
    border-right: none;
`;

const SearchButton = styled.button`
    background-color: #1abb9b;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #117864;
    }
`;

const ResetButton = styled.button`
    background-color: #e74c3c;
    color: white;
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #c0392b;
    }
`;

// Стили для пагинации
const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;

const PaginationButton = styled.button`
    background-color: #1abb9b;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px;

    &:hover {
        background-color: #117864;
    }

    &:disabled {
        background-color: #ddd;
        cursor: not-allowed;
    }
`;

const PageInfo = styled.span`
    margin: 0 10px;
    font-weight: bold;
`;

const CeilingList = () => {
    const [images, setImages] = useState([]);
    const [selectedCeilingId, setSelectedCeilingId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [search, setSearch] = useState('');  // Состояние для ввода поиска
    const [searchQuery, setSearchQuery] = useState('');  // Состояние для отправки поиска
    const [page, setPage] = useState(1);  // Состояние для текущей страницы
    const [totalPages, setTotalPages] = useState(1);  // Общее количество страниц
    const [limit, setLimit] = useState(null);  // Состояние для лимита (будет установлено позже)
    const [isReady, setIsReady] = useState(false);  // Состояние готовности компонента
    const navigate = useNavigate();

    // Установка лимита в зависимости от ширины экрана при первой загрузке
    useEffect(() => {
        if (window.innerWidth <= 768) {
            setLimit(1);  // Устанавливаем limit в 1 для мобильных экранов
        } else {
            setLimit(6);  // Устанавливаем limit в 6 для десктопов
        }
        setIsReady(true);  // Компонент готов к отправке запросов
    }, []);  // Пустой массив зависимостей, чтобы эффект сработал только при первой загрузке

    // Функция для получения списка изображений
    const fetchImages = async () => {
        if (!isReady || limit === null) return;  // Ждем, пока компонент не будет готов

        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.get(`/ceiling/list`, {
                headers: {
                    'X-Token': token,
                },
                params: {
                    search: searchQuery,
                    page: page,
                    limit: limit,  // Используем динамический лимит
                }
            });
            setImages(response.data.data);
            setTotalPages(response.data.page_amount);  // Обновляем количество страниц
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
    };

    // Открытие модального окна с подтверждением удаления
    const openDeleteModal = (ceiling_id) => {
        setSelectedCeilingId(ceiling_id);
        setIsModalOpen(true);
    };

    // Закрытие модального окна
    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Подтверждение удаления
    const handleDeleteConfirm = async () => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.delete(`/ceiling/delete?ceiling_id=${selectedCeilingId}`, {
                headers: {
                    'X-Token': token,
                }
            });
            setImages(images.filter(image => image.sk !== 'CEILING#' + selectedCeilingId));
            closeModal();
        } catch (error) {
            console.error("Ошибка при удалении:", error);
        }
    };

    // Переход на создание нового товара
    const handlerCreate = () => {
        navigate('/create-ceiling');
    };

    // Переход на обновление товара
    const handlerUpdate = (item) => {
        navigate('/update-ceiling', { state: { item } });
    };

    // Обработка ввода в поле поиска
    const handleSearchInputChange = (event) => {
        setSearch(event.target.value);
    };

    // Поиск по кнопке
    const handleSearchButtonClick = () => {
        setSearchQuery(search);
        setPage(1);  // Сбрасываем на первую страницу при поиске
    };

    // Сброс поиска
    const handleResetSearch = () => {
        setSearch('');
        setSearchQuery('');
        setPage(1);  // Сбрасываем на первую страницу при сбросе
    };

    // Обработка перехода на следующую страницу
    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    // Обработка перехода на предыдущую страницу
    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    useEffect(() => {
        fetchImages();
    }, [page, searchQuery, limit, isReady]);

    return (
        <>
            <Container>
                <Sidebar />
                <Content>
                    <CatalogTitle>Каталог</CatalogTitle>
                    <CreateButton onClick={handlerCreate}>
                        Создать
                    </CreateButton>

                    {/* Поисковый контейнер */}
                    <SearchContainer>
                        <SearchInput
                            type="text"
                            placeholder="Поиск по названию или коду..."
                            value={search}
                            onChange={handleSearchInputChange}
                        />
                        <SearchButton onClick={handleSearchButtonClick}>
                            Найти
                        </SearchButton>
                        <ResetButton onClick={handleResetSearch}>
                            Сбросить
                        </ResetButton>
                    </SearchContainer>

                    <CatalogGrid>
                        {images.map((item, index) => (
                            <Card key={index}>
                                <CardImage src={item.image} alt={item.title} />
                                <CardTitle>{item.title}</CardTitle>
                                <CardText>Цена: {item.price_per_square} сом/м²</CardText>
                                <CardText>Код: {item.code}</CardText>
                                <ButtonGroup>
                                    <UpdateButton onClick={() => handlerUpdate(item)}>
                                        Редактировать
                                    </UpdateButton>
                                    <DeleteButton onClick={() => openDeleteModal(item.sk.split('#')[1])}>
                                        Удалить
                                    </DeleteButton>
                                </ButtonGroup>
                            </Card>
                        ))}
                    </CatalogGrid>

                    {/* Пагинация и информация о страницах */}
                    <PaginationWrapper>
                        <PaginationButton onClick={handlePreviousPage} disabled={page === 1}>
                            Назад
                        </PaginationButton>
                        <PageInfo>{`Страница ${page} из ${totalPages}`}</PageInfo>
                        <PaginationButton onClick={handleNextPage} disabled={page === totalPages}>
                            Вперед
                        </PaginationButton>
                    </PaginationWrapper>
                </Content>
            </Container>

            {/* Модальное окно подтверждения удаления */}
            {isModalOpen && (
                <Modal isOpen={isModalOpen} onClose={closeModal} onConfirm={handleDeleteConfirm}>
                    <h2>Подтверждение удаления</h2>
                    <p>Вы уверены, что хотите удалить этот элемент?</p>
                </Modal>
            )}
        </>
    );
};

export default CeilingList;
