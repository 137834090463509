// src/App.js
import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import ImageList from './constructor/ImageList';
import Login from './admin/Login';
import Register from './admin/Register';
import CreateCeiling from './admin/CreateCeiling';
import {Container} from '@mui/material';
import CeilingList from "./admin/CeilingList";
import Landing from "./Landing";
import Profile from "./admin/Profile";
import Logout from "./admin/Logout";
import Admin from "./admin/Admin";
import UpdateCeiling from "./admin/UpdateCeiling";
import ApplicationList from "./admin/ApplicationList";
import ApplicationDetails from "./admin/ApplicationDetails";
import SubmitApplication from "./constructor/SubmitApplication";

const PrivateRoute = ({children}) => {
    const auth = localStorage.getItem('authToken');
    return auth ? children : <Navigate to="/login"/>;
};

function App() {
    return (
        <div style={{fontFamily: 'LXGW WenKai Mono TC, serif'}}>
            <Router>
                <Container>
                    <Routes>
                        <Route path="/" element={<Landing/>}/>
                        <Route path="/admin" element={<PrivateRoute><Admin/></PrivateRoute>}/>
                        <Route path="/application-list" element={<PrivateRoute><ApplicationList /></PrivateRoute>}/>
                        <Route path="/catalog" element={<PrivateRoute><CeilingList/></PrivateRoute>}/>
                        <Route path="/profile" element={<PrivateRoute><Profile/></PrivateRoute>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/create-ceiling" element={<PrivateRoute><CreateCeiling/></PrivateRoute>}/>
                        <Route path="/update-ceiling" element={<UpdateCeiling />} />
                        <Route path="/application-details" element={<PrivateRoute><ApplicationDetails/></PrivateRoute>}/>
                        <Route path="/ceiling/*" element={<ImageList />}/>
                        <Route path="/submit-application" element={<SubmitApplication/>}/>
                        <Route path="/logout" element={<PrivateRoute><Logout/></PrivateRoute>}/>
                    </Routes>
                </Container>
            </Router>
        </div>
    );
}

export default App;
