import React, { useState } from 'react';
import axios from '../../axiosConfig';
import { useLocation, useNavigate } from 'react-router-dom';

const SubmitApplication = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Data passed from the composition selection page
    const { ceiling_pk, ceiling_sk, length, width, amount, callBackUrl } = location.state || {};

    // State for controlled forms
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        telegram: '',
        whatsapp: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false); // For loading state
    const [error, setError] = useState(null); // For displaying errors

    // Function to validate form data
    const validateValues = (inputValues) => {
        let errors = {};

        // Check if at least one contact field is filled
        if (!inputValues.email && !inputValues.phone && !inputValues.telegram && !inputValues.whatsapp) {
            errors.contact = 'Пожалуйста, укажите хотя бы один способ связи';
        }

        return errors;
    };

    // Handler for input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Handler for form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);

        const validationErrors = validateValues(formData);

        if (Object.keys(validationErrors).length > 0) {
            setError(validationErrors.contact || validationErrors.email);
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await axios.post('/application/submit', {
                ceiling_pk,
                ceiling_sk,
                length,
                width,
                amount,
                ...formData,
            });

            if (response.status === 200) {
                navigate(callBackUrl, { state: { message: 'Заявка успешно отправлена!' } });
            }
        } catch (err) {
            const errorMessage = err.response?.data?.message || 'Не удалось отправить заявку. Пожалуйста, попробуйте снова.';
            if (errorMessage.includes('Value is not a valid email address')) {
                setError('Недопустимый адрес электронной почты. Пример: someone@example.com');
            } else {
                setError(errorMessage);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Оформление заявки</h2>
            <form onSubmit={handleSubmit} style={styles.form}>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Имя*</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        style={styles.input}
                    />
                </div>

                <div style={styles.formGroup}>
                    <label style={styles.label}>Email*</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        style={styles.input}
                    />
                </div>

                <div style={styles.formGroup}>
                    <label style={styles.label}>Телефон</label>
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        style={styles.input}
                    />
                </div>

                <div style={styles.formGroup}>
                    <label style={styles.label}>Telegram</label>
                    <input
                        type="text"
                        name="telegram"
                        value={formData.telegram}
                        onChange={handleInputChange}
                        style={styles.input}
                    />
                </div>

                <div style={styles.formGroup}>
                    <label style={styles.label}>WhatsApp</label>
                    <input
                        type="text"
                        name="whatsapp"
                        value={formData.whatsapp}
                        onChange={handleInputChange}
                        style={styles.input}
                    />
                </div>

                <div style={styles.infoGroup}>
                    <label style={styles.infoLabel}>Длина: {length} м</label>
                    <br/>
                    <label style={styles.infoLabel}>Ширина: {width} м</label>
                    <br/>
                    <label style={styles.infoLabel}>Итоговая сумма: {amount} сом</label>
                </div>

                <div style={styles.formGroup}>
                    {error && <p style={styles.errorMessage}>{error}</p>}
                </div>

                <button type="submit" style={styles.submitButton} disabled={isSubmitting}>
                    {isSubmitting ? 'Отправка...' : 'Отправить заявку'}
                </button>
            </form>
        </div>
    );
};

// Styles
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px',
        backgroundColor: '#f7f7f7',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '600px',
        margin: '0 auto',
    },
    title: {
        fontSize: '24px',
        color: '#333',
        marginBottom: '20px',
        textAlign: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    formGroup: {
        marginBottom: '20px',
    },
    label: {
        display: 'block',
        marginBottom: '8px',
        fontSize: '16px',
        color: '#333',
    },
    input: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        fontSize: '16px',
    },
    infoGroup: {
        marginBottom: '20px',
        padding: '10px',
        backgroundColor: '#fff',
        borderRadius: '5px',
        border: '1px solid #ddd',
        textAlign: 'left',
    },
    infoLabel: {
        fontSize: '16px',
        color: '#666',
        marginBottom: '10px',
    },
    submitButton: {
        backgroundColor: '#5485CBFF',
        color: 'white',
        padding: '12px 20px',
        fontSize: '18px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
    errorMessage: {
        color: 'red',
        marginTop: '10px',
    },
};

export default SubmitApplication;