import React from 'react';

function PageNotFound() {
    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f8f9fa',
        },
        text: {
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#343a40',
        },
    };

    return (
        <div style={styles.container}>
            <h3 style={styles.text}>Страница не найдено. Перепроверьте ссылку.</h3>
        </div>
    );
}

export default PageNotFound;
