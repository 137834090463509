import React, { useEffect } from 'react';
import { useNavigate } from "react-router";
import Sidebar from "./Sidebar";
import axios from "../../axiosConfig";

const styles = {
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',  // Высота экрана
        padding: '20px',
    },
    welcomeText: {
        textAlign: 'center',  // Центрирование текста
        marginTop: '20px',
        fontFamily: "'LXGW WenKai Mono TC', serif",
        fontSize: '1.2em',
    },
    welcomeTextDesktop: {
        fontSize: '1.5em',
        marginTop: '40px',
    },
    sidebarContainer: {
        position: 'fixed',
        left: 0,
        top: 0,
        height: '100vh',
        width: '250px',
        backgroundColor: '#2c3e50',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
    }
};

const Admin = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('authToken');

        if (!token || token === 'null') {
            navigate('/login');
        }

        const checkToken = async () => {
            try {
                await axios.get('/user/profile', {
                    headers: {
                        'X-Token': token,
                    },
                });
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            }
        };

        checkToken();
    }, [navigate]);

    return (
        <div style={styles.contentContainer}>
            <Sidebar style={styles.sidebarContainer} />
            <div style={styles.welcomeText}>
                <h1>Добро пожаловать в SKY-BORDER!</h1>
                <p>
                    Мы рады сообщить, что вы теперь наш партнер. Откройте для себя новые возможности
                    для улучшения вашего бизнес-процесса с нашими профессиональными решениями.
                </p>
            </div>
        </div>
    );
};

export default Admin;
