import React, { useState } from 'react';
import './Modal.css'; // Подключаем стили для модального окна

const Modal = ({ isOpen, onClose, onConfirm, children }) => {
    const [isVisible, setIsVisible] = useState(isOpen);

    // Обработчик закрытия модального окна
    const handleClose = () => {
        setIsVisible(false);
        onClose();
    };

    // Обработчик подтверждения
    const handleConfirm = () => {
        setIsVisible(false);
        onConfirm();
    };

    // Если модальное окно не открыто, не рендерим ничего
    if (!isVisible) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-content">
                    {children}
                    <div className="button-container">
                        <button className="modal-button confirm" onClick={handleConfirm}>
                            Да
                        </button>
                        <button className="modal-button cancel" onClick={handleClose}>
                            Нет
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
