import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import '../../Sidebar.css';

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [pendingCount, setPendingCount] = useState(0); // Хранение количества необработанных заявок

    // Функция для переключения сайдбара
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    // Запрос к эндпоинту для получения количества необработанных заявок
    useEffect(() => {
        const fetchPendingCount = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get('/application/pending-count', {
                    headers: {
                        'X-Token': token,
                    },
                });
                setPendingCount(response.data); // Установка числа необработанных заявок
            } catch (error) {
                console.error('Ошибка при получении количества необработанных заявок:', error);
            }
        };

        fetchPendingCount();
    }, []);

    return (
        <>
            <div className={`hamburger ${isOpen ? 'white' : 'black'}`} onClick={toggleSidebar}>
                <div></div>
                <div></div>
                <div></div>{pendingCount > 0 && (
                <span className="pending-count-point"></span>
            )}
            </div>
            <div className={`sidebar-container ${isOpen ? 'active' : ''}`}>
                <a href='/admin'>
                    <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Sky-Border logo" className="logo-img" />
                </a>
                <nav className="sidebar-menu">
                    <a href="/profile" className="sidebar-item">Профиль</a>
                    <a href="/application-list" className="sidebar-item">
                        Заявки
                        {pendingCount > 0 && (
                            <span className="pending-count-badge">{pendingCount}</span>
                        )}
                    </a>
                    <a href="/catalog" className="sidebar-item">Каталог</a>
                    <a href="/logout" className="sidebar-item">Выход</a>
                </nav>
            </div>
        </>
    );
};

export default Sidebar;
