import React, { useRef } from 'react';
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import { RoundedBox } from "@react-three/drei";

function Table(props) {
    const woodTexture = useLoader(TextureLoader, '/textures/wood.jpg');
    const material = useRef();

    woodTexture.repeat.set(0.5, 0.5);

    const tableScale = 0.70;

    return (
        <group scale={[tableScale, tableScale, tableScale]} {...props}>
            <group position={[0, 0.8, 0]}>
                {/* Table Top */}
                <RoundedBox args={[2, 0.05, 1]} radius={0.02} smoothness={1}>
                    <meshStandardMaterial ref={material} map={woodTexture} />
                </RoundedBox>
                {/* Legs */}
                <mesh position={[-0.9, -0.4, -0.4]}>
                    <cylinderGeometry args={[0.05, 0.02, 0.8, 32]} />
                    <meshStandardMaterial color="black" />
                </mesh>
                <mesh position={[0.9, -0.4, -0.4]}>
                    <cylinderGeometry args={[0.05, 0.02, 0.8, 32]} />
                    <meshStandardMaterial color="black" />
                </mesh>
                <mesh position={[-0.9, -0.4, 0.4]}>
                    <cylinderGeometry args={[0.05, 0.02, 0.8, 32]} />
                    <meshStandardMaterial color="black" />
                </mesh>
                <mesh position={[0.9, -0.4, 0.4]}>
                    <cylinderGeometry args={[0.05, 0.02, 0.8, 32]} />
                    <meshStandardMaterial color="black" />
                </mesh>
            </group>
        </group>
    );

}

export default Table;
