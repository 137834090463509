import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import styled from 'styled-components';
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    height: 100vh;
    background-color: #f0f4f8;
`;

const Content = styled.div`
    flex: 1;
    padding: 15px;  /* Уменьшили отступы */
    display: flex;
    flex-direction: column;
`;

const FormContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 15px;  /* Уменьшили расстояние между элементами */
    width: 100%;
    max-width: 700px;  /* Уменьшили максимальную ширину */
    margin: 0 auto;
    padding: 20px;  /* Уменьшили отступы внутри формы */
    background-color: #ffffff;
    border-radius: 8px;  /* Сделали более компактные углы */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const StatusContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;  /* Уменьшили отступ */
`;

const ButtonStyled = styled(Button)`
    width: 48%;
    padding: 8px 16px;  /* Уменьшили высоту кнопок */
    font-size: 14px;  /* Немного уменьшили шрифт */
`;

const BackButton = styled(Button)`
    margin-top: 10px;  /* Уменьшили отступ сверху */
    width: 100%;
    padding: 8px 16px;  /* Уменьшили высоту кнопки */
    font-size: 14px;  /* Немного уменьшили шрифт */
`;

const ApplicationDetails = () => {
    const location = useLocation();
    const { application } = location.state || {};
    const navigate = useNavigate();

    const [length, setLength] = useState(application?.length || '');
    const [width, setWidth] = useState(application?.width || '');
    const [amount, setAmount] = useState(application?.amount || '');
    const [description, setDescription] = useState(application?.description || '');
    const [status, setStatus] = useState(application?.status || 'PENDING');
    const [cancelLoading, setCancelLoading] = useState(false);
    const [successLoading, setSuccessLoading] = useState(false);

    const handleUpdate = async (newStatus) => {
        if (newStatus === 'SUCCESS'){
            setSuccessLoading(true);
        } else {
            setCancelLoading(true)
        }
        try {
            const token = localStorage.getItem('authToken');
            await axios.post('/application/update', {
                pk: application.pk,
                sk: application.sk,
                length,
                width,
                amount,
                description,
                status: newStatus,
            }, {
                headers: { 'X-Token': token },
            });
            setStatus(newStatus);
            navigate('/application-list'); // Перенаправление на список заявок после обновления
        } catch (error) {
            console.error('Ошибка при обновлении:', error);
        } finally {
            setCancelLoading(false);
            setSuccessLoading(false);
        }
    };

    const isDisabled = status === 'CANCELED' || status === 'SUCCESS'; // Определяем, когда поля должны быть неактивными

    return (
        <Container>
            <Content>
                <FormContainer>
                    <Typography variant="h5" gutterBottom>Детали заявки</Typography>  {/* Уменьшили размер заголовка */}

                    <TextField
                        label="Имя"
                        value={application?.name || 'Не указано'}
                        fullWidth
                        disabled
                        size="small"
                        InputLabelProps={{
                            style: { color: 'black' },
                        }}
                    />
                    <TextField
                        label="Товар"
                        value={application?.ceiling || 'Не указано'}
                        fullWidth
                        disabled
                        size="small"
                        InputLabelProps={{
                            style: { color: 'black' },
                        }}
                    />

                    <TextField
                        label="Длина"
                        type="number"
                        value={length}
                        onChange={(e) => setLength(e.target.value)}
                        fullWidth
                        disabled={isDisabled}
                        size="small"
                        InputLabelProps={{
                            style: { color: 'black' },
                        }}
                    />
                    <TextField
                        label="Ширина"
                        type="number"
                        value={width}
                        onChange={(e) => setWidth(e.target.value)}
                        fullWidth
                        disabled={isDisabled}
                        size="small"
                        InputLabelProps={{
                            style: { color: 'black' },
                        }}
                    />
                    <TextField
                        label="Сумма"
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        fullWidth
                        disabled={isDisabled}
                        size="small"
                        InputLabelProps={{
                            style: { color: 'black' },
                        }}
                    />
                    <TextField
                        label="Email"
                        value={application?.email || 'Не указан'}
                        fullWidth
                        disabled
                        size="small"
                        InputLabelProps={{
                            style: { color: 'black' },
                        }}
                    />
                    <TextField
                        label="Телефон"
                        value={application?.phone || 'Не указан'}
                        fullWidth
                        disabled
                        size="small"
                        InputLabelProps={{
                            style: { color: 'black' },
                        }}
                    />
                    <TextField
                        label="Telegram"
                        value={application?.telegram || 'Не указан'}
                        fullWidth
                        disabled
                        size="small"
                        InputLabelProps={{
                            style: { color: 'black' },
                        }}
                    />
                    <TextField
                        label="Whatsapp"
                        value={application?.whatsapp || 'Не указан'}
                        fullWidth
                        disabled
                        size="small"
                        InputLabelProps={{
                            style: { color: 'black' },
                        }}
                    />
                    <TextField
                        label="Описание"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        disabled={isDisabled}
                        size="small"
                        multiline
                        minRows={2}
                        maxRows={4}
                        InputLabelProps={{
                            style: { color: 'black' },  // Черный цвет для метки
                        }}
                    />

                    <TextField
                        label="Статус"
                        value={
                            status === 'PENDING' ? 'В ожидании' :
                                status === 'SUCCESS' ? 'Обработано' :
                                    'Отменено'
                        }
                        fullWidth
                        disabled
                        size="small"
                        InputLabelProps={{
                            style: { color: 'black' },  // Метка будет черной
                        }}
                    />

                    {/* Кнопки для изменения статуса только если статус PENDING */}
                    {status === 'PENDING' && (
                        <StatusContainer>
                            <ButtonStyled
                                variant="contained"
                                color="secondary"
                                onClick={() => handleUpdate('CANCELED')}
                                disabled={cancelLoading}
                            >
                                {cancelLoading ? <CircularProgress size={24} color="inherit" /> : 'Отменить'}
                            </ButtonStyled>
                            <ButtonStyled
                                variant="contained"
                                color="primary"
                                onClick={() => handleUpdate('SUCCESS')}
                                disabled={successLoading}
                            >
                                {successLoading ? <CircularProgress size={24} color="inherit" /> : 'Обработать'}
                            </ButtonStyled>
                        </StatusContainer>
                    )}

                    <BackButton
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate('/application-list')}
                    >
                        Вернуться в список заявок
                    </BackButton>
                </FormContainer>
            </Content>
        </Container>
    );
};

export default ApplicationDetails;
