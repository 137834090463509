import React, { useState } from 'react';
import axios from '../../axiosConfig';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Стилизация компонентов с использованием styled-components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    background-color: #f0f0f0;
`;

const FormContainer = styled.div`
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;

const StyledButton = styled.button`
    width: 100%;
    background-color: #1abc9c;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;

    &:hover {
        background-color: #16a085;
    }
`;

const LinkButton = styled.a`
    display: block;
    text-align: center;
    margin-top: 20px;
    color: #3498db;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const ErrorText = styled.p`
    color: red;
    text-align: center;
    margin-top: 10px;
`;

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [patronymic, setPatronymic] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const navigate = useNavigate();

    const handleUsernameChange = (e) => {
        const value = e.target.value;
        setUsername(value.toLowerCase());

        const latinRegex = /^[a-zA-Z0-9.@_-]*$/;

        if (latinRegex.test(value.toLowerCase())) {
            setUsernameError('');
        } else {
            setUsernameError('Имя пользователя должно содержать только следующие символы: латинские буквы (a-z), цифры (0-9), точка (.), дефис (-), подчеркивание (_), собачка (@).');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        if (usernameError) {
            return;
        }

        try {
            await axios.post('/user/register', {
                username,
                password,
                name,
                surname,
                patronymic,
                contact: { phone_number: phoneNumber }
            });
            navigate('/login');
        } catch (error) {
            setError(error.response?.data?.message || 'Произошла ошибка при регистрации');
        }
    };

    return (
        <Container>
            <FormContainer>
                <h2 style={{ textAlign: 'center' }}>Регистрация</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Пользователь"
                        value={username}
                        onChange={handleUsernameChange}
                        style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                        required
                    />
                    {usernameError && <ErrorText>{usernameError}</ErrorText>}
                    <input
                        type="password"
                        placeholder="Пароль"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Имя"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Фамилия"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                    />
                    <input
                        type="text"
                        placeholder="Отчество"
                        value={patronymic}
                        onChange={(e) => setPatronymic(e.target.value)}
                        style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                    />
                    <input
                        type="text"
                        placeholder="Номер телефона"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                    />
                    {error && <ErrorText>{error}</ErrorText>}
                    <StyledButton type="submit">
                        Зарегистрироваться
                    </StyledButton>
                </form>

                <LinkButton onClick={() => navigate('/login')}>
                    Обратно в страницу входа
                </LinkButton>

                <LinkButton href="https://sky-border.com" target="_blank" rel="noopener noreferrer">
                    https://sky-border.com
                </LinkButton>
            </FormContainer>
        </Container>
    );
};

export default Register;
