import React  from 'react';
import Modal from '../utils/Modal';
import {useNavigate} from "react-router";

const Logout = () => {

    const navigate = useNavigate()

    const closeModal = () => {
        navigate('/admin');
    };

    const handleConfirm = () => {
        localStorage.setItem('authToken', null);
        navigate('/admin');
    };

    return (
        <div>
            <Modal isOpen={true} onClose={closeModal} onConfirm={handleConfirm}>
                <h2>Вы уверены?</h2>
                <p>Вы точно хотите выйти?</p>
            </Modal>
        </div>
    );
};

export default Logout;
