import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, useMediaQuery, Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function Navbar() {
    const [isMobile, setIsMobile] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const mobileView = useMediaQuery('(max-width:768px)');

    useEffect(() => {
        setIsMobile(mobileView);
    }, [mobileView]);

    return (
        <AppBar position="static">
            <Toolbar
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: isMobile ? '10px' : '0 20px',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={`${process.env.PUBLIC_URL}/logo.png`}
                        alt="Sky-Border logo"
                        width={'120'}
                        height={'120'}
                        style={{ marginRight: isMobile ? '0' : '10px', position: isMobile ? 'relative' : 'absolute', zIndex: 10, top: '5px'}}
                    />
                </Box>

                {/* Container for menu items and mobile menu button */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end', // Align items to the right
                        flex: 1,
                    }}
                >
                    {isMobile && (
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            sx={{ fontSize: '2rem', position: menuOpen ? 'absolute' : 'relative', top: menuOpen ? '0' : 'auto', right: menuOpen ? '0' : 'auto', zIndex: 11 }} // Position the close button at the top when menu is open
                            onClick={() => setMenuOpen(!menuOpen)}
                        >
                            {menuOpen ? <CloseIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
                        </IconButton>
                    )}

                    {/* Menu items */}
                    <Box
                        sx={{
                            display: isMobile ? (menuOpen ? 'flex' : 'none') : 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            alignItems: 'center',
                            width: isMobile ? '100%' : 'auto',
                            transition: 'all 0.3s ease-in-out',
                            justifyContent: isMobile ? 'flex-start' : 'flex-end', // Align menu items to the right on desktop
                        }}
                    >
                        <Button color="inherit" href="#about" sx={{ fontSize: isMobile ? '14px' : 'inherit' }}>О нас</Button>
                        <Button color="inherit" href="#features" sx={{ fontSize: isMobile ? '14px' : 'inherit' }}>Преимущества</Button>
                        <Button color="inherit" href="#contact" sx={{ fontSize: isMobile ? '14px' : 'inherit' }}>Контакты</Button>
                        <Button color="inherit" href="/admin" sx={{ fontSize: isMobile ? '14px' : 'inherit' }}>Партнерам</Button>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;