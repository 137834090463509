import React, { useEffect, useState } from 'react';
import axios from '../../axiosConfig';
import { useNavigate } from "react-router";
import Sidebar from "./Sidebar";
import styled from 'styled-components';
import { Modal, Box, TextField, Button } from '@mui/material';

const ProfileContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
`;

const ProfileCard = styled.div`
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 600px;
    text-align: center;
`;

const FieldLabel = styled.span`
    color: #777;
    font-weight: 500;
`;

const FieldValue = styled.span`
    color: #000;
    font-weight: 600;
`;

const ProfileText = styled.p`
    font-family: 'LXGW WenKai Mono TC, serif';
    margin: 10px 0;
`;

const ProfileError = styled.p`
    font-family: 'LXGW WenKai Mono TC, serif';
    margin: 10px 0;
    color: red;
`;

const ProfileLink = styled.a`
    color: #1abc9c;
    text-decoration: none;
    word-wrap: break-word;
    &:hover {
        text-decoration: underline;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
`;

const Profile = () => {
    const [profile, setProfile] = useState({
        name: '',
        surname: '',
        patronymic: '',
        username: '',
        password: '',
        email: '',
        chat_id: '',
        contact: {
            phone_number: '',
        },
        status: '',
        url_to_constructor: '',
    });
    const [error, setError] = useState('');
    const [emailModalOpen, setEmailModalOpen] = useState(false);
    const [telegramModalOpen, setTelegramModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [telegramCode, setTelegramCode] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const getProfile = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get('/user/profile', {
                    headers: {
                        'X-Token': token,
                    },
                });
                setProfile(response.data.user);
                setError('');
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
                setError(error.response?.data?.message || 'Ошибка при загрузке профиля');
            }
        };

        getProfile();
    }, [navigate]);

    // Функция для добавления email
    const handleAddEmail = async () => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.post('/user/email/add', { email }, {
                headers: { 'X-Token': token },
            });
            setEmailModalOpen(false);
            refreshProfile();
        } catch (error) {
            const errorMessage = error.response?.data?.message

            if (errorMessage.includes('Value is not a valid email address')) {
                setError('Недопустимый адрес электронной почты. Пример: someone@example.com');
            } else {
                setError('Ошибка при добавлении email');
            }
        }
    };

    // Функция для добавления Telegram
    const handleAddTelegram = async () => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.post('/user/telegram/add', { code: telegramCode }, {
                headers: { 'X-Token': token },
            });
            setTelegramModalOpen(false);
            refreshProfile(); // Обновляем профиль после добавления Telegram
        } catch (error) {
            setError(error.response?.data?.message || 'Ошибка при добавлении Telegram');
        }
    };

    // Функция обновления профиля
    const refreshProfile = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.get('/user/profile', {
                headers: { 'X-Token': token },
            });
            setProfile(response.data.user);
            setError('');
        } catch (error) {
            setError(error.response?.data?.message || 'Ошибка при обновлении профиля');
        }
    };

    return (
        <ProfileContainer>
            <Sidebar />
            <ProfileCard>
                <h2>Профиль</h2>
                <ProfileText>
                    <FieldLabel>Имя: </FieldLabel>
                    <FieldValue>{profile.name || 'Не указано'}</FieldValue>
                </ProfileText>
                <ProfileText>
                    <FieldLabel>Фамилия: </FieldLabel>
                    <FieldValue>{profile.surname || 'Не указана'}</FieldValue>
                </ProfileText>
                <ProfileText>
                    <FieldLabel>Отчество: </FieldLabel>
                    <FieldValue>{profile.patronymic || 'Не указано'}</FieldValue>
                </ProfileText>
                <ProfileText>
                    <FieldLabel>Пользователь: </FieldLabel>
                    <FieldValue>{profile.username}</FieldValue>
                </ProfileText>
                <ProfileText>
                    <FieldLabel>Email: </FieldLabel>
                    <FieldValue>{profile.email || 'Не указан'}</FieldValue>
                </ProfileText>
                <ProfileText>
                    <FieldLabel>Telegram: </FieldLabel>
                    <FieldValue>{profile.chat_id || 'Не указан'}</FieldValue>
                </ProfileText>
                <ProfileText>
                    <FieldLabel>Телефон: </FieldLabel>
                    <FieldValue>{profile.contact.phone_number || 'Не указан'}</FieldValue>
                </ProfileText>
                <ProfileText>
                    <FieldLabel>Статус: </FieldLabel>
                    <FieldValue>{profile.status === 'ACTIVE' ? 'Активный' : 'Неактивный'}</FieldValue>
                </ProfileText>
                <ProfileText>
                    <FieldLabel>Личная ссылка для конструктора: </FieldLabel>
                    <ProfileLink href={profile.url_to_constructor}>{profile.url_to_constructor}</ProfileLink>
                </ProfileText>
                {error && <ProfileError>{error}</ProfileError>}

                {/* Кнопки добавления */}
                <ButtonContainer>
                    <Button variant="contained" color="primary" onClick={() => setEmailModalOpen(true)}>
                        Добавить Email
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => setTelegramModalOpen(true)}>
                        Добавить Telegram
                    </Button>
                </ButtonContainer>
            </ProfileCard>

            {/* Модальное окно для добавления Email */}
            <Modal open={emailModalOpen} onClose={() => setEmailModalOpen(false)}>
                <Box sx={{ padding: '20px', backgroundColor: 'white', margin: 'auto', borderRadius: '10px', maxWidth: '400px' }}>
                    <h3>Добавить Email</h3>
                    <TextField
                        label="Email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                    />
                    <Button variant="contained" color="primary" onClick={handleAddEmail}>
                        Добавить
                    </Button>
                </Box>
            </Modal>

            {/* Модальное окно для добавления Telegram */}
            <Modal open={telegramModalOpen} onClose={() => setTelegramModalOpen(false)}>
                <Box sx={{ padding: '20px', backgroundColor: 'white', margin: 'auto', borderRadius: '10px', maxWidth: '400px' }}>
                    <h3>Добавить Telegram</h3>
                    <br/>
                    <ProfileLink href="https://t.me/sb_services_bot" target="_blank">Ссылка на телеграм бот для получения кода</ProfileLink>
                    <TextField
                        label="Код Telegram"
                        fullWidth
                        value={telegramCode}
                        onChange={(e) => setTelegramCode(e.target.value)}
                        margin="normal"
                    />
                    <Button variant="contained" color="primary" onClick={handleAddTelegram}>
                        Добавить
                    </Button>
                </Box>
            </Modal>
        </ProfileContainer>
    );
};

export default Profile;
