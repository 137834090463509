import React, { useEffect, useState } from 'react';
import axios from '../../axiosConfig';
import styled from 'styled-components';
import { useNavigate } from "react-router";
import Sidebar from "./Sidebar";

// Стили для контейнера и карточек
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Content = styled.div`
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
`;

const ApplicationTitle = styled.h2`
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
`;

// Стили для контейнера поиска и кнопок
const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`;

const SearchInput = styled.input`
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px 0 0 5px;
    width: 100%;
    max-width: 400px;
    border-right: none;
`;

const SearchButton = styled.button`
    background-color: #1abb9b;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #117864;
    }
`;

const ResetButton = styled.button`
    background-color: #e74c3c;
    color: white;
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #c0392b;
    }
`;

const TableContainer = styled.div`
    overflow-x: auto;
    width: 100%;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    min-width: 600px;

    @media (max-width: 768px) {
        min-width: 100%; /* Обеспечиваем, чтобы таблица полностью помещалась */
    }
`;

const TableHead = styled.thead`
    background-color: #f5f5f5;
`;

const TableRow = styled.tr`
    background-color: ${(props) => props.status === 'PENDING' ? '#e3f2fd' : '#ffffff'};
    &:hover {
        background-color: #f1f1f1;
    }
`;

const TableCell = styled.td`
    padding: 10px;
    border: 1px solid #ddd;
    text-align: right;

    /* Скрываем колонки, которые не нужны на мобильных устройствах */
    @media (max-width: 768px) {
        &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            display: none; /* Скрываем столбцы "Товар", "Сумма", "Описание" */
        }
    }
`;

const StatusCell = styled(TableCell)`
    color: ${(props) => {
        if (props.status === 'PENDING') return '#ff9800';
        if (props.status === 'SUCCESS') return '#4caf50';
        if (props.status === 'CANCELED' || props.status === 'CANCELLED') return '#f44336';
        return '#000';
    }};
    font-weight: bold;
`;

const Button = styled.button`
    background-color: #1abb9b;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex-shrink: 0;

    &:hover {
        background-color: #117864;
    }
`;

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 10px;
`;

const PaginationButton = styled.button`
    background-color: #1abb9b;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex-shrink: 0;
    margin: 0 5px;

    &:hover {
        background-color: #117864;
    }

    &:disabled {
        background-color: #ddd;
        cursor: not-allowed;
    }
`;

const PageInfo = styled.span`
    margin: 0 10px;
    font-weight: bold;
`;

const ApplicationList = () => {
    const [applications, setApplications] = useState([]);
    const [search, setSearch] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setLimit(8);  // Устанавливаем limit в 1 для мобильных экранов
        } else {
            setLimit(10);  // Устанавливаем limit в 6 для десктопов
        }
        setIsReady(true);  // Компонент готов к отправке запросов
    }, []);

    const fetchApplications = async () => {
        if (!isReady || limit === null) return;
        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.get(`/application/list`, {
                headers: {
                    'X-Token': token,
                },
                params: {
                    search: searchQuery,
                    page: page,
                    limit: limit,
                }
            });
            setApplications(response.data.data);
            setTotalPages(response.data.page_amount);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
    };

    const translateStatus = (status) => {
        switch (status) {
            case 'PENDING':
                return 'В ожидании';
            case 'SUCCESS':
                return 'Обработано';
            case 'CANCELED':
            case 'CANCELLED':
                return 'Отменено';
            default:
                return status;
        }
    };

    const handleSearchInputChange = (event) => {
        setSearch(event.target.value);
    };

    const handleSearchButtonClick = () => {
        setSearchQuery(search);
        setPage(1);
    };

    const handleResetSearch = () => {
        setSearch('');
        setSearchQuery('');
        setPage(1);
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleDetailsClick = (application) => {
        navigate('/application-details', { state: { application } });
    };

    useEffect(() => {
        fetchApplications();
    }, [page, searchQuery, isReady]);

    return (
        <Container>
            <Sidebar />
            <Content>
                <ApplicationTitle>Список заявок</ApplicationTitle>

                {/* Поисковый контейнер */}
                <SearchContainer>
                    <SearchInput
                        type="text"
                        placeholder="Поиск по имени, email, телефону и т.д."
                        value={search}
                        onChange={handleSearchInputChange}
                    />
                    <SearchButton onClick={handleSearchButtonClick}>
                        Найти
                    </SearchButton>
                    <ResetButton onClick={handleResetSearch}>
                        Сбросить
                    </ResetButton>
                </SearchContainer>

                {/* Таблица заявок */}
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Имя</TableCell>
                                <TableCell>Товар</TableCell> {/* Скрывается на мобильных */}
                                <TableCell>Сумма</TableCell> {/* Скрывается на мобильных */}
                                <TableCell>Описание</TableCell> {/* Скрывается на мобильных */}
                                <TableCell>Статус</TableCell>
                                <TableCell>Действия</TableCell>
                            </TableRow>
                        </TableHead>
                        <tbody>
                        {applications.length > 0 ? (
                            applications.map((application) => (
                                <TableRow key={application.pk} status={application.status}>
                                    <TableCell>{application.name}</TableCell>
                                    <TableCell>{application.ceiling || '—'}</TableCell>
                                    <TableCell>{application.amount}</TableCell>
                                    <TableCell>{application.description || '—'}</TableCell>
                                    <StatusCell status={application.status}>
                                        {translateStatus(application.status)}
                                    </StatusCell>
                                    <TableCell>
                                        <Button onClick={() => handleDetailsClick(application)}>
                                            Подробнее
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={10}>Нет данных</TableCell>
                            </TableRow>
                        )}
                        </tbody>
                    </Table>
                </TableContainer>

                {/* Пагинация */}
                <PaginationWrapper>
                    <PaginationButton onClick={handlePreviousPage} disabled={page === 1}>
                        Назад
                    </PaginationButton>
                    <PageInfo>{`Страница ${page} из ${totalPages}`}</PageInfo>
                    <PaginationButton onClick={handleNextPage} disabled={page === totalPages}>
                        Вперед
                    </PaginationButton>
                </PaginationWrapper>
            </Content>
        </Container>
    );
};

export default ApplicationList;
