import React, { useEffect, useState } from 'react';
import {
    Typography,
    Button,
    Container,
    Grid,
    TextField,
    Paper,
    Box,
    CssBaseline
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "./utils/Navbar";
import emailjs from 'emailjs-com';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#ff4081',
        },
    },
    typography: {
        fontFamily: 'Roboto, Arial',
    },
});

function Landing() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_n0kzrtp',       // replace with your EmailJS service ID
            'template_qwfxk48',       // replace with your EmailJS template ID
            {
                name: formData.name,
                email: formData.email,
                message: formData.message,
            },
            '5-n-AdXik5e_lGIYj'            // replace with your EmailJS user ID
        ).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert('Сообщение отправлено успешно!');
        }).catch((error) => {
            console.error('FAILED...', error);
            alert('Ошибка при отправке сообщения. Пожалуйста, попробуйте снова.');
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navbar/>
            {/* Hero Section */}
            <Box
                sx={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/hero-image.jpg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: '#fff',
                    textAlign: 'center',
                    py: { xs: 6, md: 10 },
                }}
                data-aos="fade-in"
            >
                <Container maxWidth="md">
                    <Typography variant="h2" component="h1" gutterBottom sx={{ fontSize: { xs: '2rem', md: '3rem' } }}>
                        Создайте уникальный дизайн вашего потолка
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}>
                        Наш конструктор позволяет визуализировать натяжные потолки в 3D
                    </Typography>
                    <br/>
                    <Button variant="contained" color="secondary" size="large" href="#features">
                        Узнать больше
                    </Button>
                </Container>
            </Box>

            {/* О нас */}
            <Container id="about" sx={{ py: { xs: 6, md: 8 } }}>
                <Typography variant="h4" component="h2" gutterBottom data-aos="fade-up">
                    О нас
                </Typography>
                <Typography variant="body1" paragraph>
                    Sky-Border предоставляет инструмент для компаний, занимающихся установкой натяжных потолков. Наш конструктор
                    позволяет вашим клиентам визуализировать потолки в 3D, выбирая из широкого ассортимента дизайнов и материалов.
                </Typography>
            </Container>

            {/* Преимущества */}
            <Box sx={{ bgcolor: '#f5f5f5', py: { xs: 6, md: 8 } }} id="features">
                <Container>
                    <Typography variant="h4" component="h2" gutterBottom data-aos="fade-up">
                        Наши преимущества
                    </Typography>
                    <Grid container spacing={4}>
                        {[
                            {
                                title: 'Интуитивно понятный интерфейс конструктора',
                                description: 'Легко создавайте и настраивайте дизайн потолка с помощью нашего удобного интерфейса.',
                            },
                            {
                                title: 'Высококачественные 3D визуализации',
                                description: 'Получайте реалистичные 3D изображения ваших проектов для презентаций клиентам.',
                            },
                            {
                                title: 'Широкий выбор дизайнов и материалов',
                                description: 'Выбирайте из множества доступных материалов и стилей для создания уникальных решений.',
                            },
                            {
                                title: 'Поддержка и обучение для ваших сотрудников',
                                description: 'Мы предоставляем полную поддержку и обучение для эффективного использования нашего конструктора.',
                            },
                        ].map((feature, index) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                key={index}
                                data-aos="fade-up"
                                data-aos-delay={`${index * 100}`}
                            >
                                <Typography variant="h6" gutterBottom>
                                    {feature.title}
                                </Typography>
                                <Typography variant="body1">{feature.description}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>

            {/* Contact Us Section */}
            <Container id="contact" sx={{ py: { xs: 6, md: 8 } }}>
                <Typography variant="h4" component="h2" gutterBottom data-aos="fade-up">
                    Свяжитесь с нами
                </Typography>
                <Paper sx={{ p: { xs: 2, md: 4 } }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Имя"
                                    name="name"
                                    fullWidth
                                    required
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    type="email"
                                    fullWidth
                                    required
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Сообщение"
                                    name="message"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    required
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary">
                                    Отправить
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>

            {/* Footer */}
            <Box sx={{ bgcolor: 'primary.main', color: '#fff', py: 2 }}>
                <Container>
                    <Typography variant="body1" align="center">
                        © {new Date().getFullYear()} Sky-Border. Все права защищены.
                    </Typography>
                </Container>
            </Box>
        </ThemeProvider>
    );
}

export default Landing;
