import React, { useEffect, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Room from "./Room";
import {useMediaQuery} from "@mui/material";
import '../../RoomConstructor.css'
import Ceiling from "./Ceiling";

function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

function RoomConstructor({ width, depth, height, floor, light, ceiling_image }) {
    const ceiling_height = height - 1.5;
    const canvasRef = useRef(null); // Реф для канваса
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const lightMeshes = [];

    if (light) {
        lightMeshes.push(
            <>
                <ambientLight intensity={0.5} />
                <directionalLight position={[0, 1, 0]} intensity={1} />
                <pointLight position={[0, 1, 0]} />
            </>
        );
    }

    // Проверка ширины экрана для адаптации
    const mobileView = useMediaQuery('(max-width:768px)');

    useEffect(() => {
        setIsMobile(mobileView);
    }, [mobileView]);

    // Функция для переключения полноэкранного режима
    const toggleFullscreen = () => {
        const canvasElement = canvasRef.current;
        if (!isFullscreen) {
            if (canvasElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
                canvasElement.webkitRequestFullscreen();
            } else if (canvasElement.mozRequestFullScreen) { // Firefox
                canvasElement.mozRequestFullScreen();
            } else if (canvasElement.requestFullscreen) {
                canvasElement.requestFullscreen();
            } else if (canvasElement.msRequestFullscreen) { // IE/Edge
                canvasElement.msRequestFullscreen();
            }
            setIsFullscreen(true);
        } else {
            if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            setIsFullscreen(false);
        }
    };

    return (
        <div ref={canvasRef} style={{position: 'relative', width: '100%', height: isMobile ? '30vh' : '55vh'}}>
            <Canvas style={{width: '100%', height: '100%'}}>
                {lightMeshes}
                <Room width={width} depth={depth} height={height} floor={floor} position={[0, -1.5, 1.5]}/>
                <Ceiling width={width} depth={depth} position={[0, ceiling_height, 1.5]} base64Image={ceiling_image}/>
                <OrbitControls/>
            </Canvas>
            <div className="container">
                <div className="item">
                    <div className="caption" style={{fontSize: isMobile ? '9px': '14px'}}>Назад</div>
                    <img src={isMobile ? "/back.png" : "/c-back.png"} alt="Назад" style={{height : isMobile ? '20px': '50px',
                        marginLeft: isMobile ? '6px' : '20px'}}/>
                </div>
                <div className="item">
                    <div className="caption" style={{fontSize: isMobile ? '9px': '14px'}}>Вперед</div>
                    <img src={isMobile ? "/forward.png" : "/c-forward.png"} alt="Вперед" style={{height : isMobile ? '20px': '50px',
                        marginLeft: isMobile ? '6px' : '20px'}}/>
                </div>
                <div className="item">
                    <div className="caption" style={{fontSize: isMobile ? '9px': '14px'}}>В сторону</div>
                    <img src={isMobile ? "/position.png" : "/c-position.png"} alt="В сторону" style={{height : isMobile ? '20px': '50px',
                        marginLeft: isMobile ? '6px' : '20px'}}/>
                </div>
                <div className="item" style={{fontSize: isMobile ? '9px': '14px'}}>
                    <div className="caption">Вращение</div>
                    <img src={isMobile ? "/turn.png" : "/c-turn.png"} alt="Поворот" style={{height : isMobile ? '20px': '50px',
                        marginLeft: isMobile ? '6px' : '20px'}}/>
                </div>
            </div>

            {!isIOS() && (
                <button
                    onClick={toggleFullscreen}
                    style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        padding: '10px',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        zIndex: 1000
                    }}
                >
                    {isFullscreen ? 'Выйти из полноэкранного режима' : 'Во весь экран'}
                </button>
            )}
        </div>
    );
}

export default RoomConstructor;
