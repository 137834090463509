import React, { useRef } from 'react';
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";

function Ceiling({ width, depth, base64Image, ...props }) {
    const texture = useLoader(TextureLoader, base64Image);
    const material = useRef();

    return (
        <mesh {...props} >
            <boxGeometry args={[width, 0.05, depth]} />
            <meshStandardMaterial ref={material} map={texture} />
        </mesh>
    );
}

export default Ceiling;
