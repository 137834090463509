import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../axiosConfig';
import '../../ImageList.css';
import RoomConstructor from "./RoomConstructor";
import PageNotFound from "./PageNotFound";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom"; // Для навигации на страницу оформления заявки

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const ImageList = () => {
    // State Management
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedImageTitle, setSelectedImageTitle] = useState('');
    const [price, setSelectedImagePrice] = useState(0);

    const [dimensions, setDimensions] = useState({
        height: 3,
        width: 4,
        depth: 5
    });

    const [settings, setSettings] = useState({
        floor: true,
        light: true
    });

    const [texts, setTexts] = useState({
        floorText: 'Убрать пол',
        lightText: 'Выключить свет'
    });

    const [dataExist, setDataExist] = useState(true);
    const [loading, setLoading] = useState(true);

    const [activeToggle, setActiveToggle] = useState(null); // Track which toggle is active

    const currentURL = window.location.pathname;
    const navigate = useNavigate(); // Для навигации

    // Fetch Images on Component Mount or URL Change
    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(currentURL);
                const imageArray = response.data.data;
                for (let i = 0; i < imageArray.length; i++) {
                    try {
                        const response = await fetch(imageArray[i].image);
                        const blob = await response.blob();
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            imageArray[i].image = reader.result;
                        };
                        reader.readAsDataURL(blob);
                    } catch (error) {
                        console.error('Error fetching the image:', error);
                    }
                }

                if (imageArray.length > 0) {
                    setImages(imageArray);
                    const firstImage = imageArray[0];
                    setSelectedImage(firstImage.image);
                    setSelectedItem(firstImage);
                    setSelectedImageTitle(firstImage.title.toUpperCase());
                    setSelectedImagePrice(firstImage.price_per_square);
                }

                setLoading(false);
            } catch (error) {
                setDataExist(false);
                console.error('Error fetching images:', error);
                setLoading(false);
            }
        };

        fetchImages();
    }, [currentURL]);

    // Handler for Image Click
    const handleImageClick = useCallback((image) => {
        setSelectedImage(image.image);
        setSelectedItem(image);
        setSelectedImageTitle(image.title.toUpperCase());
        setSelectedImagePrice(image.price_per_square);
    }, []);

    // Handler for Input Changes
    const handleInputChange = useCallback((e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            setSettings(prev => ({ ...prev, [name]: checked }));

            setTexts(prev => ({
                ...prev,
                [`${name}Text`]: checked
                    ? name === 'floor' ? 'Убрать пол' : 'Выключить свет'
                    : name === 'floor' ? 'Показать пол' : 'Включить свет'
            }));
        } else {
            setDimensions(prev => ({
                ...prev,
                [name]: parseFloat(value)
            }));
        }
    }, []);

    const calculatedPrice = Math.round(dimensions.width * dimensions.depth * price);

    const handleSubmitApplication = () => {
        navigate('/submit-application', {
            state: {
                ceiling_pk: selectedItem.pk,
                ceiling_sk: selectedItem.sk,
                length: dimensions.depth,
                width: dimensions.width,
                amount: calculatedPrice,
                callBackUrl: currentURL
            }
        });
    };

    // Loading Spinner
    if (loading) {
        return (
            <div className="spinner-container">
                <div className="spinner" aria-label="Loading"></div>
            </div>
        );
    }

    // Handle No Data Scenario
    if (!dataExist) {
        return <PageNotFound />;
    }

    // Toggle Handlers
    const handleToggleCarousel = () => {
        setActiveToggle(prev => prev === 'carousel' ? null : 'carousel');
    };

    const handleToggleInput = () => {
        setActiveToggle(prev => prev === 'input' ? null : 'input');
    };

    return (
        <div className="image-list-container">
            {/* Image Title */}
            <div className='image-title'>
                <h4>Композиция "{selectedImageTitle}"</h4>
            </div>

            {/* Selected Room Display */}
            <div className="selected-room">
                {selectedImage && (
                    <RoomConstructor
                        width={dimensions.width}
                        depth={dimensions.depth}
                        height={dimensions.height}
                        floor={settings.floor}
                        light={settings.light}
                        ceiling_image={selectedImage}
                    />
                )}
            </div>

            {/* Toggle Buttons */}
            <div className="toggle-container">
                <button
                    onClick={handleToggleCarousel}
                    className="toggle-button"
                >
                    {activeToggle === 'carousel' ? 'Закрыть выбор композиции' : 'Выбрать композицию'}
                </button>
                <button
                    onClick={handleToggleInput}
                    className="toggle-button"
                >
                    {activeToggle === 'input' ? 'Закрыть настройки' : 'Открыть настройки'}
                </button>
            </div>

            {/* Input Controls */}
            <div className={`input-container ${activeToggle === 'input' ? 'open' : ''}`}>
                {/* Price Display */}
                <div className='price-row'>
                    <label>
                        <h4>Цена: {calculatedPrice} сом</h4>
                    </label>
                </div>

                {/* Dimension Sliders */}
                <div className="input-container__row">
                    <label>
                        Ширина: {dimensions.width} м
                        <input
                            type="range"
                            name="width"
                            min="2"
                            max="15"
                            step="0.1"
                            value={dimensions.width}
                            onChange={handleInputChange}
                            aria-label="Adjust width"
                        />
                    </label>
                    <label>
                        Глубина: {dimensions.depth} м
                        <input
                            type="range"
                            name="depth"
                            min="2"
                            max="15"
                            step="0.1"
                            value={dimensions.depth}
                            onChange={handleInputChange}
                            aria-label="Adjust depth"
                        />
                    </label>
                    <label>
                        Высота: {dimensions.height} м
                        <input
                            type="range"
                            name="height"
                            min="2"
                            max="15"
                            step="0.1"
                            value={dimensions.height}
                            onChange={handleInputChange}
                            aria-label="Adjust height"
                        />
                    </label>
                </div>{/* Toggle Switches */}
                <div className="toggle-row">
                    <div className="toggle-switch">
                        <label>
                            <input
                                type="checkbox"
                                name="floor"
                                checked={settings.floor}
                                onChange={handleInputChange}
                                aria-checked={settings.floor}
                                aria-label="Toggle floor visibility"
                            />
                            <span className="slider"></span>
                            {texts.floorText}
                        </label>
                    </div>
                    <div className="toggle-switch">
                        <label>
                            <input
                                type="checkbox"
                                name="light"
                                checked={settings.light}
                                onChange={handleInputChange}
                                aria-checked={settings.light}
                                aria-label="Toggle light"
                            />
                            <span className="slider"></span>
                            {texts.lightText}
                        </label>
                    </div>
                </div>
            </div>

            {/* Image Carousel */}
            <div className={`carousel-container ${activeToggle === 'carousel' ? 'open' : ''}`}>
                {activeToggle === 'carousel' && (
                    <Carousel
                        responsive={responsive}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        arrows={true}
                        infinite={true}
                        autoPlay={false}
                        keyBoardControl={true}
                        containerClass="carousel-container"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {images.map((image, index) => (
                            <div
                                className='image-item'
                                key={image.id || index}
                                onClick={() => handleImageClick(image)}
                                role="button"
                                tabIndex={0}
                                onKeyPress={(e) => { if (e.key === 'Enter') handleImageClick(image); }}
                                aria-label={`Select ${image.title}`}
                            >
                                <img
                                    src={image.image}
                                    alt={image.title}
                                    loading="lazy"
                                />
                                <div className="image-item__overlay">
                                    <p>{image.title}: {image.code}</p>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                )}
            </div>

            {/* Button to Submit Application */}
            <div className="submit-button-container">
                <button
                    onClick={handleSubmitApplication}
                    className="submit-button"
                >
                    Оформить заявку
                </button>
            </div>
        </div>
    );
};

export default ImageList;
