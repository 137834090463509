import React, { useRef } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';

const Lamp = ({...props}) => {
    const lampRef = useRef();
    const [woodTexture, metalTexture, fabricTexture] = useTexture([
        '/textures/wood.jpg',
        '/textures/metal.jpg',
        '/textures/fabric.jpg',
    ]);

    const textureScale = 0.65;

    return (
        <group ref={lampRef} scale={[textureScale, textureScale, textureScale]} {...props}>
            {/* Lamp Base */}
            <mesh position={[0, 0.05, 0]}>
                <cylinderGeometry args={[0.22, 0.25, 0.05, 32]} />
                <meshStandardMaterial map={woodTexture} />
            </mesh>

            {/* Lamp Stand */}
            <mesh position={[0, 0.4, 0]}>
                <cylinderGeometry args={[0.03, 0.03, 0.7, 32]} />
                <meshStandardMaterial map={metalTexture} />
            </mesh>

            {/* Lamp Shade Frame */}
            <mesh position={[0, 0.5, 0]}>
                <cylinderGeometry args={[0.20, 0.20, 0.02, 32]} />
                <meshStandardMaterial map={metalTexture} />
            </mesh>

            {/* Lamp Shade */}
            <mesh position={[0, 0.75, 0]}>
                <cylinderGeometry args={[0.15, 0.25, 0.3, 32]} />
                <meshStandardMaterial map={fabricTexture} side={THREE.DoubleSide} />
            </mesh>

            {/* Light Bulb */}
            <mesh position={[0, 0.75, 0]}>
                <sphereGeometry args={[0.18, 32, 32]} />
                <meshStandardMaterial emissive="yellow" emissiveIntensity={2} />
            </mesh>

            {/* Light Source */}
            <pointLight intensity={1} position={[0, 0.75, 0]} />
        </group>
    );
};

export default Lamp;
